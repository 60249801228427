import {Helmet} from 'react-helmet-async';
import {connect} from 'react-redux';
import React, {Component} from 'react';

import MarketLayout from 'web/components/market_layout';

import TermsLayout from '../components/terms_layout';

class GiftCardsPage extends Component {
  static reducer(state, action) {
    return MarketLayout.reducer(state, action);
  }

  renderGiftCardTerms() {
    return (
      <div>
        <h3>Good Eggs Gift Card Terms &amp; Conditions</h3>
        <p>Effective Date: July 2020</p>
        <h4>Introduction</h4>
        <p>
          By purchasing, accepting or using the Good Eggs Gift Card ({'"'}Gift Card{'"'}) and in
          consideration for your right to use the Gift Card, you agree to be bound by these terms
          and conditions (the {'"'}Gift Card Terms &amp; Conditions{'"'}) and the terms and
          conditions contained in the Good Eggs Terms of Service (the {'"'}Terms of Service{'"'}).
          In these Gift Card Terms &amp; Conditions, {'"'}you{'"'} or {'"'}your{'"'} means either
          the cardholder who has purchased a Gift Card or the recipient of a Gift Card (as
          applicable). {'"'}We{'"'} or {'"'}our{'"'} means Good Eggs Inc. Good Eggs respects your
          privacy and will only use your personal information as described in our Privacy Policy.
        </p>
        <h4>Purchase</h4>
        <p>
          Gift Cards are electronic and may be purchased at www.goodeggs.com or on the Good Eggs iOS
          app. In order to purchase the Gift Card, you will need to have or open a Good Eggs
          account. For purposes of delivering the Gift Card only, you will need to provide the name
          and email address of the recipient.
        </p>
        <h4>Redemption</h4>
        <p>
          The Gift Card may only be used for purchases online at www.goodeggs.com or on the Good
          Eggs iOS app. Invalid or unidentifiable Gift Card numbers will not be redeemed. To redeem
          the Gift Card, you will need to have an existing Good Eggs account or create a new one.
          The Gift Card is not a credit card, charge card, or bank debit card and can only be used
          for the amount of funds loaded onto the Gift Card. The funds loaded on the Gift Card are
          collected by Good Eggs and deposited with and held by us in a pooled account with funds
          associated with other Gift Cards. You will not be paid or earn interest on the amount of
          the Gift Card. When making a purchase with the Gift Card, the value of the purchase plus
          any shipping/handling fees and sales tax, if applicable, will be automatically deducted
          from your Gift Card balance. If a purchase exceeds the amount of the Gift Card balance,
          the remaining balance must be paid with an additional payment method accepted by Good
          Eggs. You may check your Gift Card balance online at www.goodeggs.com.
        </p>
        <h4>Expiration</h4>
        <p>
          The funds on the Gift Card do not expire. No fees apply. However, in certain states, after
          a certain period of time, Good Eggs may remit the cash associated with unused Gift Card
          balances to certain states pursuant to their abandoned property laws. Once the Gift Card
          balance has been remitted to such a state, the Gift Card may no longer be redeemed, and
          Good Eggs may direct you to the state government instead.
        </p>
        <h4>Restrictions</h4>
        <ul>
          <li>Gift Card users must be 18 years or older. </li>
          <li>The prepaid value of the Gift Card cannot exceed $1,000. </li>
          <li>Coupons, discounts, and other Gift Cards may not be used to buy Gift Cards. </li>
          <li>
            The Gift Card cannot be {'"'}reloaded{'"'} (i.e. you cannot add value/balance to your
            Gift Card after purchase
          </li>
          <li>
            Upon first use, the funds on the Gift Card are redeemed as an account credit to the Good
            Eggs account associated with the Gift Card{"'"}s first use and become non-transferable.
          </li>
          <li>
            {' '}
            The Gift Card is not refundable or redeemable for cash or credit, except to the extent
            required by law. If your balance is below $10, and you would like to know if you qualify
            for cash redemption, please contact us at help@goodeggs.com. and we will verify whether
            you qualify for cash redemption under applicable law.
          </li>
          <li>
            {' '}
            There may be a 24 hour waiting period between the purchase of a Gift Card and when that
            Gift Card can be redeemed or used toward a purchase.
          </li>
        </ul>
        <h4>Risk of Loss </h4>
        <p>
          Please safeguard your Gift Card and treat it like cash. Title and risk of loss for the
          Gift Card pass to the recipient immediately upon purchase. Good Eggs is not responsible if
          a Gift Card is lost, stolen, destroyed, or used without the recipient{"'"}s permission.
          Lost or stolen Gift Cards may only be replaced or credited if the Gift Card has never been
          used, and only with satisfactory proof of purchase, where required by law.
        </p>
        <h4>Reservation of Rights</h4>
        <p>
          Good Eggs reserves the right to correct the Gift Card balance if we believe that a
          clerical, billing, or accounting error has occurred. Good Eggs also reserves the right to
          deactivate, cancel, or suspend any Gift Card if we suspect fraud, unlawful activity, or
          improper Gift Card use.
        </p>
        <h4>Disclaimer of Warranties; Limitation of Liability</h4>
        <p>
          WITHOUT LIMITING THE DISCLAIMER OF WARRANTIES CONTAINED IN THE GOOD EGGS TERMS OF SERVICE
          AND TO THE EXTENT PERMITTED BY APPLICABLE LAW, GOOD EGGS AND ITS AFFILIATES MAKE NO
          REPRESENTATIONS, WARRANTIES, OR CONDITIONS OF ANY KIND, EXPRESS OR IMPLIED, WITH RESPECT
          TO THE GIFT CARD OR ANY PURCHASES MADE WITH THE GIFT CARD, INCLUDING, BUT NOT LIMITED TO,
          ANY EXPRESS OR IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
          Some jurisdictions do not allow limitations on implied warranties or the exclusion or
          limitation of certain damages. If these laws apply to you, some or all of the above
          disclaimers, exclusions, or limitations may not apply to you, and you might have
          additional rights. IN ADDITION TO THE EXCLUSION OF DAMAGES SET OUT IN THE GOOD EGGS TERMS
          OF SERVICE , YOU AGREE THAT GOOD EGGS AND ITS AFFILIATES SHALL HAVE NO LIABILITY FOR LOST
          PROFITS OR ANY SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN
          CONNECTION WITH THESE GIFT CARD TERMS &amp; CONDITIONS (HOWEVER ARISING, INCLUDING
          NEGLIGENCE).
          <br />
          IN ADDITION, TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL GOOD EGGS OR ITS
          AFFILIATES HAVE ANY LIABILITY FOR ANY DAMAGES OR LOSSES (INCLUDING, BUT NOT LIMITED TO
          LOST PROFITS OR ANY SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES) RESULTING DIRECTLY OR
          INDIRECTLY FROM: (A) UNAUTHORIZED ACCESS TO, OR ALTERATION, THEFT OR DESTRUCTION OF A GIFT
          CARD THROUGH ACCIDENT, MISUSE, OR FRAUDULENT MEANS OR DEVICES BY YOU OR ANY THIRD PARTY;
          (B) ANY CIRCUMSTANCES BEYOND GOOD EGGS{"'"}S CONTROL; (C) THE USE OF THE GIFT CARD BEING
          SUSPENDED OR PROHIBITED BECAUSE IT HAS BEEN REPORTED LOST OR STOLEN, OR GOOD EGGS BELIEVES
          IT IS BEING USED SUSPICIOUSLY OR FRAUDULENTLY OR IS BEING SUBJECTED TO UNAUTHORIZED USE;
          (D) THROUGH NO FAULT OF GOOD EGGS, YOUR NOT HAVING SUFFICIENT FUNDS ON A GIFT CARD TO
          COVER A TRANSACTION; OR (E) INABILITY TO USE A GIFT CARD BECAUSE GOOD EGGS{"'"}S SYSTEM IS
          NOT WORKING PROPERLY. YOU AGREE THAT GOOD EGGS AND ITS AFFILIATES ARE NOT RESPONSIBLE FOR
          ANY INTERRUPTION OF SERVICE. The laws of certain states or other jurisdictions may not
          allow limitations on implied warranties, or the exclusion or limitation of certain
          damages, so the above limitation or exclusion may not apply to you. NOTWITHSTANDING
          ANYTHING TO THE CONTRARY, IN THE EVENT THAT WE OR OUR RESPECTIVE AFFILIATES ARE FOUND
          LIABLE TO YOU, YOU SHALL ONLY BE ENTITLED TO RECOVER ACTUAL AND DIRECT DAMAGES IN AN
          AMOUNT NOT TO EXCEED THE AMOUNT LOADED ONTO YOUR GIFT CARD. In the event a Gift Card is
          non-functional, your sole remedy, and Good Eggs{"'"}s sole liability, shall be the
          replacement of such Gift Card.
        </p>
        <h4>Modification </h4>
        <p>
          Good Eggs reserves the right to make changes to these Gift Card Terms &amp; Conditions at
          any time without notice to you. If Good Eggs makes a material modification to these Gift
          Card Terms &amp; Conditions, it may provide notice by displaying a notice on the Good Eggs
          website, by sending an email to any address you may have used to register for a Good Eggs
          account, or through other mechanisms. Any amendment to the Gift Card Terms &amp;
          Conditions will be effective when posted on www.goodeggs.com.
        </p>
        <h4>General</h4>
        <p>
          If any of these Gift Card Terms &amp; Conditions are deemed invalid, void, or for any
          reason unenforceable, the parties agree that the court should endeavor to give effect to
          the parties{"'"} intentions as reflected in the provision, and the unenforceable condition
          shall be deemed severable and shall not affect the validity and enforceability of any
          remaining terms. These Gift Card Terms &amp; Conditions and the relationship between you
          and Good Eggs will be governed by the laws of the State of California without regard to
          its conflict of law provisions. You and Good Eggs agree to submit to the personal
          jurisdiction of the federal and state courts located in the State of California with
          respect to any legal proceedings that may arise in connection with these Gift Card Terms
          &amp; Conditions. Any dispute related to these Gift Card Terms &amp; Conditions shall be
          finally settled by confidential arbitration in San Francisco County, California.
          Arbitration under the Streamlined Arbitration Rules and Procedures of Judicial Arbitration
          and Mediation Services, Inc. ({'"'}JAMS{'"'}) then in effect. The arbitrator{"'"}s award
          shall be binding and may be entered as a judgment in any court of competent jurisdiction.
          Notwithstanding the foregoing obligation to arbitrate disputes, each party shall have the
          right to pursue injunctive or other equitable relief at any time, from any court of
          competent jurisdiction. Any arbitration under these Gift Card Terms &amp; Conditions will
          take place on an individual basis: class arbitrations and class actions are not permitted.
          YOU UNDERSTAND AND AGREE THAT BY ENTERING INTO THESE TERMS, YOU AND GOOD EGGS ARE EACH
          WAIVING THE RIGHT TO TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION. The failure of
          Good Eggs to act with respect to a breach by you or others does not waive its right to act
          with respect to subsequent or similar breaches.
        </p>
      </div>
    );
  }

  render() {
    return (
      <MarketLayout disableUpcomingOrdersBanner disableBasketDropdown>
        <Helmet>
          <title>Gift Card Terms &amp; Conditions | Good Eggs</title>
        </Helmet>

        <TermsLayout currentSlug="gift-cards">{this.renderGiftCardTerms()}</TermsLayout>
      </MarketLayout>
    );
  }
}

GiftCardsPage.pageName = 'Gift Card Terms & Conditions';

export default connect((state) => state)(GiftCardsPage);
